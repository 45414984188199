.timeline-container {
  display: flex;
  flex-direction: column;
}

.timeline-item {
  position: relative;
  padding-left: 2.25rem;
  display: flex;
}

.circle {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-25%, 25%);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: white;
  border: 0.25rem solid hsl(165, 56%, 51%);
  z-index: 1;
}

.timeline-item:last-child .circle {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-25%);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: white;
  border: 0.25rem solid hsl(165, 56%, 51%);
  z-index: 1;
}

.timeline-item::after {
  position: absolute;
  content: "";
  top: 1rem;
  bottom: 0;
  left: 0;
  transform: translateX(50%);
  width: 0.325rem;
  height: 100%;
  background: hsl(165, 56%, 51%);
}

.timeline-item:first-child::after {
  position: absolute;
  content: "";
  top: 1rem;
  transform: translateX(50%);
  width: 0.325rem;
  height: 100%;
  background: hsl(165, 56%, 51%);
  z-index: 0;
}

.timeline-item:last-child::after {
  display: none;
  content: "";
  height: 0;
}
