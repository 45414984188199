.image-container {
  width: 100px;
  height: 100px;
  outline: solid 1px rgb(97, 97, 97, 0.4);
  border-radius: 8px;
  cursor: pointer;
  /* Setup */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
}

.image-container .inner-container {
  display: grid;
  text-align: center;
  color: #a4a4a4;
  font-size: 16px;
  transition: 0.2s ease-in-out;
}

.image-container:hover {
  outline: solid 1px #2666a5;
  color: #2666a5;
}

.image-container .inner-container:hover {
  color: #2666a5;
}

.background-image-overlay {
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: move;
  position: absolute;
  inset: 0px;
  height: 140px;
  width: 100%;
  opacity: 0;
  transition: all 0.5s ease 0s;
  background-color: var(--N700, #31353b) AD;
  border-radius: 8px;
  z-index: 2;
}
.background-image-overlay:hover {
  opacity: 1;
}

.overlay-image-text {
  height: 55px;
  margin-bottom: 15px;
  color: var(--N0, #000);
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.image-tool {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding-right: 4px;
}

.image-tool label {
  margin: 0px 4px 8px;
  cursor: pointer;
}

/* wrapper dropzone */
.wrapper-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}
.wrapper-flex.is-column {
  flex-direction: column;
}
.wrapper-flex.is-v-center {
  align-items: center;
}
.wrapper-flex.is-h-center {
  justify-content: center;
  text-align: center;
}
.has-padding-left-15 {
  padding-left: 15px;
}
.wrapper-flex.is-h-left {
  justify-content: start;
}
.wrapper-flex.is-h-right {
  justify-content: flex-end;
}
.wrapper-flex.is-h-between {
  justify-content: space-between;
}

.wrapper-flex.is-h-center.detail-notif-empty {
  padding: 100px;
}

/* upload dropzone */
.upload-wrapper {
  width: 100%;
  height: auto;
  border: 1px solid #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  color: #666666;
}
.upload-wrapper:hover,
.upload-wrapper.is-active {
  border: 1px solid #2666a5;
  color: #2666a5 !important;
}
.upload-wrapper:hover .custom-fill,
.upload-wrapper.is-active .custom-fill {
  fill: #2666a5;
}

/* Drag n Drop */
.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #666666;
  outline: solid 1px rgb(97, 97, 97, 0.4);
  border-radius: 8px;
}

.grid-item-content {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
}
